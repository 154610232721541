$font-family: "Open Sans";
/* FONT WEIGHT */
$weight-bold: 700;
$weight-semi-bold: 600;
$weight-medium: 500;
$weight-normal: 400;
/* COLORS */
$color-red: #ce2a45;
$color-red-dark: #c8102e;
$color-white: #ffffff;
$color-black: #231f20;
$color-black-dark: #000000;
$color-gray: #f4f4f4;
$color-gray-light: #f8f8f9;
$color-gray-dark: #b5b6bd;
/* SHADOW */
$shadow: 0px 4px 15px rgba(138, 107, 118, 15%);
